// eslint-disable-next-line max-lines-per-function
define( [ 'utility' ], function run( utility ) {
	'use strict';

	var oModalFn = {
		focusableElementsString : 'a[href], area[href], input:not([disabled]), select:not([disabled]),		' +
		' textarea:not([disabled]), button:not([disabled]), iframe, object, embed, *[tabindex], *[contenteditable]',
		focusedElementBeforeModal : null,
		modalDialog : '<div class="o-modal" aria-hidden="false" aria-labelledby="modalDescription" aria-describedby="modalDescription" role="dialog">' +
		'<button class="o-modal__cancel" type="button"><span class="o-modal__cancel-inner">' + FRAMEWORK.locale.settings.oModal.closeText + '</span></button>' +
		'<div class="o-modal__content-holder" aria-live="polite" data-module="u-scrollable"><div class="o-modal__content-outer"><div class="o-modal__content" ' +
		'id="modalDescription" role="document"></div></div></div>' +
		'</div>' +
		'<div class="o-modal-overlay"><div class="a-loading-indicator-container"><div class="a-loading-indicator" role="alert">' +
		'<span class="a-loading-indicator__message">' + FRAMEWORK.locale.settings.oModal.loadingText + '</span></div></div></div>',
		rootElement : document.documentElement,
		lWholePage : document.querySelector( 'body > .l-whole-page' ),

		init : function( elm ) {
			if ( oModalFn.rootElement.classList.contains( 'js-o-modal' ) ) {
				if ( elm && !elm.classList.contains( 'o-modal-link' ) && ( elm.tagName === 'A' || elm.tagName === 'BUTTON' ) ) {
					elm.classList.add( 'o-modal-link' );
				}

				// If modal was already setup on the page then just run the button search to update the modal button list for the page
				oModalFn.rootElement.oModal.buttons = oModalFn.rootElement.querySelectorAll( 'a.o-modal-link, button.o-modal-link, a[data-module="o-modal"], button[data-module="o-modal"]' );

				Object.keys( oModalFn.rootElement.oModal.buttons ).forEach( function loop( button ) {
					if ( oModalFn.rootElement.oModal.buttons[ button ].classList.contains( 'js-o-modal__trigger' ) ) {
						// Do nothing
					} else {
						oModalFn.rootElement.oModal.buttons[ button ].classList.add( 'js-o-modal__trigger' );
						if ( oModalFn.rootElement.oModal.buttons[ button ].tagName === 'A' ) {
							oModalFn.rootElement.oModal.buttons[ button ].setAttribute( 'role', 'button' );
						}
					}
				});
			} else {
				oModalFn.rootElement.classList.add( 'js-o-modal' );

				oModalFn.rootElement.oModal = {};

				if ( elm && !elm.classList.contains( 'o-modal-link' ) && ( elm.tagName === 'A' || elm.tagName === 'BUTTON' ) ) {
					elm.classList.add( 'o-modal-link' );
				}

				oModalFn.rootElement.oModal.buttons = oModalFn.rootElement.querySelectorAll( 'a.o-modal-link, button.o-modal-link, a[data-module="o-modal"], button[data-module="o-modal"]' );

				Object.keys( oModalFn.rootElement.oModal.buttons ).forEach( function loop( button ) {
					if ( oModalFn.rootElement.oModal.buttons[ button ].classList.contains( 'js-o-modal__trigger' ) ) {
						// Do nothing
					} else {
						oModalFn.rootElement.oModal.buttons[ button ].classList.add( 'js-o-modal__trigger' );
						if ( oModalFn.rootElement.oModal.buttons[ button ].tagName === 'A' ) {
							oModalFn.rootElement.oModal.buttons[ button ].setAttribute( 'role', 'button' );
						}
					}
				});

				oModalFn.rootElement.addEventListener( 'click', function listener( e ) {
					oModalFn.modalLinkClick( e.target, e );
				});
				oModalFn.rootElement.addEventListener( 'keydown', function listener( e ) {
					var thisModal,
						notificationModal = false;
					if ( oModalFn.rootElement.classList.contains( 'o-modal-active' ) ) {
						thisModal = document.querySelector( '.o-modal' );
						oModalFn.trapTabKey( thisModal, e );

						if ( oModalFn.focusedElementBeforeModal.getAttribute( 'data-modal-notification' ) && oModalFn.focusedElementBeforeModal.getAttribute( 'data-modal-notification' ) !== '' ) {
							notificationModal = true;
						}

						// If the element that triggered the modal disable-close attribute does not equal 'all' and isn't a notification, then you can close the modal
						if ( oModalFn.focusedElementBeforeModal.getAttribute( 'data-modal-disable-close' ) !== 'all' && notificationModal === false ) {
							oModalFn.trapEscapeKey( thisModal, e );
						}
					}
				});

				window.addEventListener( 'modal:close', function fn() {
					oModalFn.closeModal();
				});
				window.addEventListener( 'modal:destroy', function fn() {
					oModalFn.destroyModal();
				});

				oModalFn.rootElement.oModal.open = function open( modalLinkElm ) {
					oModalFn.modalLinkClick( modalLinkElm );
				};
				oModalFn.rootElement.oModal.close = function close() {
					oModalFn.closeModal();
				};
				oModalFn.rootElement.oModal.destroy = function destroy() {
					oModalFn.destroyModal();
				};
			}

			return oModalFn.rootElement.oModal;
		},

		trapEscapeKey : function( obj, evt ) {
			// If escape pressed
			if ( evt.which === 27 ) {
				evt.preventDefault();
				oModalFn.closeModal();
			}
		},

		trapTabKey : function( obj, evt ) {
			var foundElements,
				focusableElements = [],
				focusedItem,
				numberOfFocusableItems,
				focusedItemIndex;
			// If tab or shift-tab pressed
			if ( evt.which === 9 ) {
				// Get list of focusable items
				foundElements = utility.find( [ obj ], oModalFn.focusableElementsString );
				foundElements.forEach( function loop( el ) {
					if ( utility.isVisible( el ) ) {
						focusableElements.push( el );
					}
				});
				focusedItem = document.querySelector( ':focus' ); // Get currently focused item
				numberOfFocusableItems = focusableElements.length; // Get the number of focusable items
				// Get the index of the currently focused item
				focusedItemIndex = focusableElements.indexOf( focusedItem );
				if ( evt.shiftKey && focusedItemIndex === 0 ) { // Back tab
					// If focused on first item and user presses back-tab, go to the last focusable item
					focusableElements[ numberOfFocusableItems - 1 ].focus();
					evt.preventDefault();
				} else if ( focusedItemIndex === -1 || ( !evt.shiftKey && focusedItemIndex === numberOfFocusableItems - 1 ) ) {
					/*
					 * Forward tab
					 * If the foucused item is outside of the allowed focus items then set to the first focusable item
					 * If focused on the last item and user presses tab, go to the first focusable item
					 */
					focusableElements[ 0 ].focus();
					evt.preventDefault();
				} else {
					// Do nothing
				}
			}
		},

		setInitialFocusModal : function( obj ) {
			var focusableElements = utility.find( [ obj ], oModalFn.focusableElementsString ),
				i;
			for ( i = 0; i < focusableElements.length; i++ ) {
				if ( utility.isVisible( focusableElements[ i ] ) ) {
					focusableElements[ i ].focus();
					break;
				}
			}
		},

		modalLinkClick : function( targetElement, event ) {
			var newTarget = targetElement.closest( '.o-modal-link, .o-modal__cancel, .a-button--cancel, [data-module="o-modal"]' ),
				preventDefault = false;

			if ( targetElement.classList.contains( 'o-modal-link' ) || targetElement.getAttribute( 'data-module' ) === 'o-modal' ) {
				preventDefault = true;
				oModalFn.openModal( targetElement );
			} else if ( targetElement.classList.contains( 'o-modal__cancel' ) || ( targetElement.closest( '.o-modal' ) && targetElement.classList.contains( 'a-button--cancel' ) ) ) {
				preventDefault = true;
				oModalFn.closeModal();
			} else if ( newTarget ) {
				// Do nothing
				preventDefault = true;
				oModalFn.modalLinkClick( newTarget, event );
			} else {
				// Do nothing
			}

			if ( preventDefault && event ) {
				event.preventDefault();
			}
		},

		openModal : function( initiatingObj, content ) {
			var waitForClose = function() {
				setTimeout( function timeoutFn() {
					if ( oModalFn.rootElement.classList.contains( 'o-modal-closing' ) ) {
						waitForClose();
					} else if ( document.querySelector( '.o-modal' ) ) {
						oModalFn.closeModal();
					} else {
						oModalFn.openModalNow( initiatingObj, content );
					}
				}, 50 ); // Wait until the modal is fully closed before trying to open the next one
			};
			waitForClose();
		},

		openModalNow : function( initiatingObj, content ) {
			var thisModal;

			oModalFn.stopBodyScrolling( true );

			// Save current focus
			oModalFn.focusedElementBeforeModal = initiatingObj;
			// Mark the main page as hidden
			oModalFn.lWholePage.setAttribute( 'aria-hidden', 'true' );
			oModalFn.lWholePage.insertAdjacentHTML( 'afterend', oModalFn.modalDialog );
			thisModal = document.querySelector( '.o-modal' );

			setTimeout( function timeoutFn() {
				oModalFn.rootElement.classList.add( 'o-modal-active' );
				// Attach a listener to redirect the tab to the modal window. If the user somehow gets out of the modal window
				oModalFn.lWholePage.addEventListener( 'focusin', oModalFn.setInitialFocusModal( thisModal ) );
				oModalFn.setInitialFocusModal( thisModal );
				oModalFn.loadContent( content );
			}, 10 ); // Small delay from adding the content to ensure the css animations work
		},

		closeModal : function() {
			if ( oModalFn.rootElement.classList.contains( 'o-modal-active' ) ) {
				oModalFn.lWholePage.setAttribute( 'aria-hidden', 'false' ); // Mark the main page as visible
				oModalFn.rootElement.classList.remove( 'o-modal-active' );
				oModalFn.rootElement.classList.remove( 'o-modal-loaded' );
				oModalFn.rootElement.classList.add( 'o-modal-closing' );
				// Remove the listener which redirects tab keys in the main content area to the modal
				oModalFn.lWholePage.removeEventListener( 'focusin', oModalFn.setInitialFocusModal( document.querySelector( '.o-modal' ) ) );

				// Set focus back to element that had it before the modal was opened
				oModalFn.focusedElementBeforeModal.focus();

				setTimeout( function timeoutFn() {
					// Save content state so that it restore the state when reopened
					var modalContent = document.querySelector( '.o-modal__content' ),
						contentOrigin,
						modalTarget;
					if ( modalContent ) {
						contentOrigin = document.querySelector( modalContent.getAttribute( 'data-origin' ) );
						if ( contentOrigin ) {
							while ( modalContent.childNodes.length ) {
								contentOrigin.append( modalContent.firstChild ); // Only grab the content inside
							}
						}
						// Remove modal from the DOM
						modalTarget = document.querySelector( '.o-modal-target' );
						if ( modalTarget ) {
							modalTarget.classList.remove( 'o-modal-target--active' );
						}
						document.querySelectorAll( '.o-modal, .o-modal-overlay' ).forEach( function loop( el ) {
							el.remove();
						});
					}
					oModalFn.rootElement.classList.remove( 'o-modal-closing' );
					oModalFn.stopBodyScrolling();

					utility.trigger( window, 'modal:closed' );
				}, FRAMEWORK.timings.current.t500ms );
			}
		},

		destroyModal : function() {
			var submitID;

			if ( oModalFn.rootElement.classList.contains( 'o-modal-active' ) ) {
				submitID = oModalFn.focusedElementBeforeModal.getAttribute( 'href' );
				oModalFn.lWholePage.setAttribute( 'aria-hidden', 'false' ); // Mark the main page as visible
				oModalFn.rootElement.classList.remove( 'o-modal-active' );
				oModalFn.rootElement.classList.remove( 'o-modal-loaded' );
				oModalFn.rootElement.classList.add( 'o-modal-closing' );
				// Remove the listener which redirects tab keys in the main content area to the modal
				oModalFn.lWholePage.removeEventListener( 'focusin', oModalFn.setInitialFocusModal( document.querySelector( '.o-modal' ) ) );
				// Set focus back to element that had it before the modal was opened
				oModalFn.focusedElementBeforeModal.focus();

				setTimeout( function timeoutFn() {
					// Remove ajaxed content from within the target div and remove modal from the DOM
					var oModalTarget = document.querySelector( '.o-modal-target' ),
						dataOrigin,
						dataUrl = oModalFn.focusedElementBeforeModal.getAttribute( 'data-url' );

					try {
						dataOrigin = oModalTarget.querySelector( submitID );
						if ( dataOrigin ) {
							dataOrigin.remove();
						}
					} catch ( e ) {
						// Do nothing
					}
					oModalTarget.classList.remove( 'o-modal-target--active' );
					if ( dataUrl && dataUrl !== '' ) {
						oModalFn.focusedElementBeforeModal.setAttribute( 'href', dataUrl );
					}
					document.querySelector( '.o-modal' ).remove();
					document.querySelector( '.o-modal-overlay' ).remove();
					oModalFn.rootElement.classList.remove( 'o-modal-closing' );
					oModalFn.stopBodyScrolling();
					utility.trigger( window, 'modal:closed' );
				}, FRAMEWORK.timings.current.t500ms );
			}
		},

		loadContent : function( content ) {
			var thisContent = content,
				getContent,
				href = oModalFn.focusedElementBeforeModal.getAttribute( 'href' ),
				hash = href.match( utility.urlAnchorRegExp ),
				staticID,
				staticContent,
				staticSource;

			document.querySelector( '.o-modal' ).classList.remove( 'o-modal--fullscreen' );
			oModalFn.rootElement.classList.remove( 'o-modal-loaded' );
			document.querySelector( '.o-modal-overlay' ).classList.remove( 'o-modal-overlay--loaded' );

			getContent = function() {
				var contentHolder = document.createElement( 'div' ),
					contentChildren;
				if ( oModalFn.focusedElementBeforeModal.getAttribute( 'data-modal-fullscreen' ) ) {
					document.querySelector( '.o-modal-overlay' ).classList.add( 'o-modal-overlay--fullscreen' );
				}
				if ( href.indexOf( '#' ) === 0 || ( href.indexOf( '/' ) === 0 &&
				window.location.pathname === oModalFn.focusedElementBeforeModal.pathname && window.location.search === oModalFn.focusedElementBeforeModal.search ) ) {
					// If url is for within the same page get content (unless the params are different incase this the app is using the params to change the page content)
					thisContent = document.querySelector( String( hash ) );
					if ( typeof thisContent === 'undefined' || thisContent === null ) {
						oModalFn.closeModal(); // No content found so close modal
					} else {
						contentChildren = [];
						thisContent.childNodes.forEach( function loop( el ) {
							contentChildren.push( el ); // Only grab the content inside
						});
						document.querySelector( '.o-modal__content' ).setAttribute( 'data-origin', hash ); // Store where the content came from so it can be restored
						oModalFn.updateContent( contentChildren );
					}
				} else {
					href = href.replace( '#', ' #' );
					utility.requestLoadInto( contentHolder, href, function fn( response, status ) {
						if ( status >= 200 && status < 400 ) {
							oModalFn.updateContent( contentHolder );
						} else {
							oModalFn.closeModal(); // No content found so close modal
						}
					});
				}
			};

			if ( typeof thisContent === 'undefined' ) {
				if ( typeof href === 'undefined' ) {
					oModalFn.closeModal(); // No content found so close modal
				} else if ( oModalFn.focusedElementBeforeModal.getAttribute( 'data-modal-fullscreen' ) && !oModalFn.focusedElementBeforeModal.getAttribute( 'href' ).match( '^#' ) ) {
					document.querySelector( '.o-modal-overlay' ).classList.add( 'o-modal-overlay--fullscreen' );
					staticID = oModalFn.focusedElementBeforeModal.getAttribute( 'href' ).split( '#' )[ 1 ];
					staticSource = oModalFn.focusedElementBeforeModal.getAttribute( 'href' ).replace( '#', ' #' );

					staticContent = document.createElement( 'div' );
					staticContent.setAttribute( 'id', staticID );
					staticContent.classList.add( 'o-modal__content-container' );

					// Fullscreen modal - get content and drop in at end of page for reuse
					utility.requestLoadInto( staticContent, staticSource, function fn( response, status ) {
						var oModalTarget = document.querySelector( '.o-modal-target' );
						oModalFn.focusedElementBeforeModal.setAttribute( 'data-url', oModalFn.focusedElementBeforeModal.getAttribute( 'href' ) ); // Set the data-url to the external link
						oModalFn.focusedElementBeforeModal.setAttribute( 'href', '#' + staticID ); // Update the href on the original anchor to the new value
						if ( status >= 200 && status < 400 ) {
							staticContent.childNodes.forEach( function loop( childEl ) {
								childEl.childNodes.forEach( function innerLoop( grandchildEl ) {
									// Move all children out of the element
									while ( grandchildEl.firstChild ) {
										childEl.insertBefore( grandchildEl.firstChild, grandchildEl );
									}
									// Remove the empty element
									childEl.removeChild( grandchildEl );
								});
							});
							oModalTarget.classList.add( 'o-modal-target--active' );
							oModalTarget.append( staticContent );
							href = oModalFn.focusedElementBeforeModal.getAttribute( 'href' ); // Update href var to that same new value
							getContent();
						} else {
							oModalFn.destroyModal(); // No content found so close modal
						}
					});
				} else {
					// Standard modal (or fullscreen with content loaded and target div populated etc)
					getContent();
				}
			}
		},

		// eslint-disable-next-line complexity
		updateContent : function( content ) {
			var thisContent = content,
				thisModal = document.querySelector( '.o-modal' ),
				thisContentHolder = document.querySelector( '.o-modal__content-holder' ),
				thisModalContent = document.querySelector( '.o-modal__content' ),
				thisModalContentHeading,
				thisModalContentLabel,
				disableClose,
				notificationModal;

			if ( thisModal ) {
				if ( oModalFn.focusedElementBeforeModal.getAttribute( 'data-modal-fullscreen' ) ) {
					thisModal.classList.add( 'o-modal--fullscreen' );
					if ( oModalFn.focusedElementBeforeModal.getAttribute( 'data-modal-wallet' ) ) {
						thisModal.classList.add( 'o-modal--wallet' );
					}
				} else {
					thisContentHolder.classList.remove( 'has-fullscreen' );
				}
				if ( Array.isArray( thisContent ) ) {
					thisContent.forEach( function loop( el ) {
						thisModalContent.append( el );
					});
				} else {
					thisModalContent.append( thisContent );
				}

				thisModalContentHeading = thisModalContent.querySelector( 'h1, h2, h3, h4, h5, .a-heading' );
				if ( thisModalContentHeading ) {
					thisModalContentLabel = thisModalContentHeading.textContent;
				} else {
					thisModalContentLabel = FRAMEWORK.locale.settings.oModal.contentLabel;
				}
				thisModalContent.setAttribute( 'aria-label', thisModalContentLabel );

				oModalFn.rootElement.classList.add( 'o-modal-loaded' );
				document.querySelector( '.o-modal-overlay' ).classList.add( 'o-modal-overlay--loaded' );

				thisContentHolder.scrollTop = 0;

				disableClose = oModalFn.focusedElementBeforeModal.getAttribute( 'data-modal-disable-close' );
				notificationModal = oModalFn.focusedElementBeforeModal.getAttribute( 'data-modal-notification' );
				thisModal.classList.remove( 'o-modal--no-close' );
				if ( disableClose || notificationModal ) {
					// If the user clicks outside the modal set focus back to the modal
					document.querySelector( '.o-modal-overlay' ).addEventListener( 'click', function listener( e ) {
						oModalFn.trapTabKey( thisModal, e );
					});

					switch ( true ) {
					case disableClose === 'all':
						thisModal.classList.add( 'o-modal--no-close' ); // Hide the close link in the header
						break;
					case notificationModal:
						thisModal.classList.add( 'o-modal--no-close' ); // Hide the close link in the header
						thisModal.classList.add( 'o-modal-notification' );
						break;
					case notificationModal === 'success':
						utility.addClass( thisModal, 'o-modal--no-close o-modal-notification o-modal-notification--success' );
						break;
					case notificationModal === 'neutral':
						utility.addClass( thisModal, 'o-modal--no-close o-modal-notification o-modal-notification--neutral' );
						break;
					case notificationModal === 'information':
						utility.addClass( thisModal, 'o-modal--no-close o-modal-notification o-modal-notification--information' );
						break;
					case notificationModal === 'warning':
						utility.addClass( thisModal, 'o-modal--no-close o-modal-notification o-modal-notification--warning' );
						break;
					default: // No match, so do nothing
						break;
					}
				} else {
					document.querySelector( '.o-modal-overlay' ).addEventListener( 'click', function listener() {
						oModalFn.closeModal();
					});
				}

				oModalFn.setInitialFocusModal( document.querySelector( '.o-modal' ) );

				utility.trigger( window, 'refresh' );
				utility.trigger( window, 'modal:opened' );
			}
		},

		stopBodyScrolling : function( bool ) {
			var htmlEl = document.querySelector( 'html' ),
				scrollTop = htmlEl.getAttribute( 'data-scrolltop' ),
				top = 0;
			if ( bool === true ) {
				if ( !htmlEl.getAttribute( 'data-scrolltop' ) ) {
					// Store the current scrollTop offset
					top = window.pageYOffset || document.body.scrollTop || document.documentElement.scrollTop;

					htmlEl.setAttribute( 'data-scrolltop', top );
					document.querySelector( '.l-whole-page' ).style.marginTop = utility.convertToPx( 0 - top );
				}
			} else if ( scrollTop ) {
				// Restore scroll top
				document.querySelector( '.l-whole-page' ).style.marginTop = 0;
				window.scrollTo( 0, htmlEl.getAttribute( 'data-scrolltop' ) );
				htmlEl.removeAttribute( 'data-scrolltop' );
			} else {
				// Do nothing
			}
		},
	};

	return {
		initInstance : function( elm ) {
			return oModalFn.init( elm );
		},
	};
});
